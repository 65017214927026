import image1 from '../assets/images/star_vip_vpn.png';
import image2 from '../assets/images/starvpn.png';
import image6 from '../assets/images/star_plus_vip.png';
import image7 from '../assets/images/jannatfastvip.png';
import image8 from '../assets/images/jsm_tunnel.png';
import image9 from '../assets/images/jannat5gnet.png';


const productData = [
    {
      id: 1,
      name: "STAR VIP NET",
      image: image1,
      productType: "openBackHeadphones",
      description: ["OVPN", "UDP", "V2RAY"],
      rating: 5,
      timeLeft: 27,
      reseller: "https://5starvip.xyz",
      appLink: "https://tinyurl.com/StarVIPvpn-Apk"
    },
    
    {
      id: 2,
      name: "STAR VPN",
      image: image2,
      productType: "openBackHeadphones",
      description: ["OVPN", "UDP", "V2RAY"],
      rating: 5,
      timeLeft: 27,
      reseller: "https://5starvip.xyz",
      appLink: "https://tinyurl.com/StarVpnNew-Apk"
    },
    {
      id: 6,
      name: "STAR PLUS VIP",
      image: image6,
      productType: "openBackHeadphones",
      description: ["OVPN", "UDP", "V2RAY"],
      rating: 5,
      timeLeft: 24,
      reseller: "https://5starvip.xyz",
      appLink: "https://tinyurl.com/StarPlusVIP-apk"
    },
    {
      id: 7,
      name: "JANNAT FAST VIP",
      image: image7,
      productType: "openBackHeadphones",
      description: ["OVPN", "UDP", "V2RAY"],
      rating: 5,
      timeLeft: 24,
      reseller: "https://jannatvip.xyz",
      appLink: "https://tinyurl.com/JannatFastVip-Apk"
    },
    {
      id: 8,
      name: "JSM TUNNEL VIP",
      image: image8,
      productType: "openBackHeadphones",
      description: ["OVPN", "UDP", "V2RAY"],
      rating: 5,
      timeLeft: 24,
      reseller: "https://jannatvip.xyz",
      appLink: "https://tinyurl.com/JSMvipTunnel-Apk"
    },
    {
      id: 9,
      name: "JANNAT VIP MAX",
      image: image9,
      productType: "openBackHeadphones",
      description: ["OVPN", "UDP", "V2RAY"],
      rating: 5,
      timeLeft: 24,
      reseller: "https://jannatvip.xyz",
      appLink: "https://tinyurl.com/JannatVipMax-Apk"
    }
  ];
  export default productData;
